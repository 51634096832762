// 表单校验
export const verify: any = {

  // 批复金额
  approveAmount( value: any, cb: any ){
    if( value === '' ){
      cb( new Error( '请填写批复金额' ) );
    }else if( Number(value) === 0 ){
      cb( new Error( '批复金额不能为 0' ) )
    }else if( !/^\d{1,8}(\.\d{1,2})?$/.test( value )) {
      cb( new Error( '至多 8 位整数、2 位小数' ) )
    }
    cb();
  },

  // 批复利率
  approveRate( value: any, cb: any ){
    if( value === '' ){
      cb();
    }else if( Number(value) === 0 ){
      cb( new Error( '放款利率不能为 0' ) )
    }else if( !/^\d{1,8}(\.\d{1,2})?$/.test( value ) ) {
      cb( new Error( '至多 8 位整数、2 位小数' ) )
    }
    cb();
  },

  // 放款金额
  loanAmount( value: any, cb: any ){
    if( value === '' ){
      cb( new Error( '请填写放款金额' ) );
    }else if( Number(value) === 0 ){
      cb( new Error( '批复金额不能为 0' ) )
    }else if( !/^\d{1,8}(\.\d{1,2})?$/.test( value ) ) {
      cb( new Error( '至多 8 位整数、2 位小数' ) )
    }
    cb();
  },

  // 放款利率
  loanRate( value: any, cb: any ){
    if( value === '' ){
      cb( new Error( '请输入放款利率' ) );
    }else if( Number(value) === 0 ){
      cb( new Error( '放款利率不能为 0' ) )
    }else if( !/^\d{1,8}(\.\d{1,2})?$/.test( value ) ) {
      cb( new Error( '至多 8 位整数、2 位小数' ) )
    }
    cb();
  },

  // 签约金额
  signAmount( value: any, cb: any ){
    if( value === '' ){
      cb( new Error( '请填写签约金额' ) );
    } else if( Number(value) === 0 ){
      cb( new Error( '签约金额不能为 0' ) );
    } else if( !/^\d{1,8}(\.\d{1,2})?$/.test( value ) ){
      cb( new Error( '至多 8 位整数、2 位小数' ) );
    }
    cb();
  },


  // 签约点位
  signPoint( value: any, cb: any ){
    if( value === '' ){
      cb( new Error( '请填写签约金额' ) );
    } else if( Number(value) === 0 ){
      cb( new Error( '签约金额不能为 0' ) );
    } else if( !/^\d{1,8}(\.\d{1,3})?$/.test( value ) ){
      cb( new Error( '至多 8 位整数、3 位小数' ) );
    }
    cb();
  },

  // 一口价服务
  serverCharge( value: any, cb: any ){
    if( value === '' ){
      cb( new Error( '请填写一口价服务费' ) );
    }else if( Number(value) === 0 ){
      cb( new Error( '一口价服务费不能为 0' ) )
    }else if( !/^\d{1,8}(\.\d{1,2})?$/.test( value )) {
      cb( new Error( '至多 8 位整数、2 位小数' ) )
    }
    cb();
  },

  // 定金
  deposit( value: any, cb: any ){
    if( value !== '' && !/^\d{1,8}(\.\d{1,2})?$/.test( value )) {
      cb( new Error( '至多 8 位整数、2 位小数' ) )
    }
    cb();
  },
  
} 

/**
 * 功能：表单校验
 */
export const validator = ( rule: any, value: any, cb: any ) => {
  const { field } = rule;
  verify[ field ]( value, cb );
}