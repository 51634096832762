/**
 * @auth 蓝天
 * 功能: 比较两个对象是否相等
 * 参数：p1, p2 JSON 对象
 */
interface Json {
  key: string
}

export const JSONDiff = (p1: Json, p2: Json, arr: string[]): boolean => JSON.stringify( p1, arr ) !== JSON.stringify( p2, arr );
