import { createStore } from 'vuex';
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import * as modules from './modules';

export default createStore({
	
	state,
	actions,
	getters,
	mutations,
	modules
});
