/**
 * 功能：将数组分组，根据特定的字段值
 * 参数：data<DataParams>, field<string>
 */
export const groupByField = ( data: any[], field: string="labelType" ): any[] => {
  return Object.values( 
    data.reduce( (prevTotal, item ) => {
      const key = item[ field ];
      if( prevTotal[ key ] ){
        prevTotal[ key ].push( item );
      }else{
        prevTotal[ key ] = [ item ]
      }
      return prevTotal
    }, {})
  );
}