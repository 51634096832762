/**
 * 功能：获取线索标签
 */
import { aLabel } from 'apis';
import { message, confirm } from 'hooks';
import { func } from 'tools';

/**
 * 功能：获取线索标签
 */
export const aGetLabelDetailByClueId = async ( ctx: any ) => {
  const { rootState: { sClueDetail: { info: { clueId } } }, commit } = ctx;
  try{
    const { data: { code, data } } = await aLabel.getLabelDetailByClueId({ clueId });
    if( code === 200 ){
      commit( 'mCheckList', data );
      return Promise.resolve( data );
    }
    return Promise.reject();
  }catch(e){
    return Promise.reject();
  }
}

/**
 * 功能：给线索添加新标签, 删除标签用同样的接口
 */
export const aLabelling = async ( ctx: any, payload: any ) => {
  const { type, tagIds } = payload;
  const { rootState: { sClueDetail: { info: { clueId } } }, dispatch } = ctx;
  const { data: { code, data } } = await aLabel.labelling({ clueId, tagIds });
  const typeStr = type == "add" ? '添加': '删除';
  if( code === 200 ){
    message.success( `标签${ typeStr }成功` );
    dispatch( 'aGetLabelDetailByClueId' );
    return Promise.resolve();
  }else{
    message.error( `标签${ typeStr }失败` );
    return Promise.reject();
  }
}

/**
 * 功能：删除线索标签
 */
export const remove = async ( ctx: any, row: any ) => {
  const { state: { checkList }, dispatch } = ctx;
  const { labelId, labelName } = row;
  const { close } = await confirm( `确认要删除“${labelName}”标签吗?` );
  if( close ){
    const curTags = checkList.filter( ( item: any ) => item.labelId != labelId ).map( (item: any) => item.labelId );
    try{
      await dispatch( 'aLabelling', { tagIds: curTags, type: 'remove' });
    }catch(e){
      
    }finally{
      close()
    }
  }
}

/**
 * 功能： 获取全部标签
 */
export const aGetLabelInfo = async ( ctx: any, payload: any ) => {
  const { commit, state: { labelInfo } } = ctx;

  try{
    const { data: { code, data } } = await aLabel.getLabelInfo();

    if( code == 200 ){
      const list = func.groupByField( (data || [] ).sort( ( a: any, b: any) => a.type - b.type ) );
      const anchors = list.map( (item: any) => ({ label: item[0].labelType }) );
      const d = { list, anchors, data }
      commit( 'mLabelInfo', d );
      return Promise.resolve( d );
    }
    return Promise.reject();
  }catch(e){
    return Promise.reject()
  }
}