 /**
 * 功能：控制文字显示数
 * 参数：count，显示文字的最大数， suffix 后缀字符
 * 返回值：string
 */
import { isString, getType } from 'class2type';

export default function( count, suffix="..." ){

  if( !isString( this ) ){
    throw `Expected an string, but got a ${ getType( this ) }`
  }

  // 2020-8-16 优化方法

  // 如果全部为汉字，值小于 count 直接返回
  if( count > this.length * 2 ) return this; 

  const len = this.strLen(); // 取得字符 长度
  
  return len < count ? this : this.substring( 0, count - 1 ) + suffix;

}
