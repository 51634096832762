/**
 * 功能：获取字符长度
 */
import { isString, getType } from 'class2type';

export default function(){

  if( !isString( this ) ){
    throw `Expected a string, but got a ${ getType( this ) }`
  }
  
  // 替换所有的双字节
  return this.replace(/([^\u0000-\u00FF])/g, 'FF' ).length;
}
