
const ENV = process.env.VUE_APP_WAY;
const baseUrl = process.env.VUE_APP_BASEURL;
const uploadUrl = process.env.VUE_APP_UPLOADURL;
const apmName = process.env.VUE_APP_ES_APM;

export default {
  ENV,
  baseURL: baseUrl,
  // 正式环境
  uploadUrl,
  OssUrl: 'https://oss.cxlqd.com/',
  apmName
};
