const auth = [
  {
    path: 'noAuth',
    name: 'noAuth',
    component: () => import('@v/loginAuth/noAuth'),
    meta: {
      noLogin: true,
    },
  },
  {
    path: 'noActive',
    name: 'noActive',
    component: () => import('@v/loginAuth/noActive'),
    meta: {
      noLogin: true,
    },
  },
  {
    path: 'registerAuth',
    name: 'registerAuth',
    component: () => import('@v/loginAuth/registerAuth'),
    meta: {
      noLogin: true,
    },
  },
];
export default auth;
