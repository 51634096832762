
// 前导 0
import prevZero from './prevZero';

import toUpper from './toUpper';

import breakAll from './breakAll';

import strLen from './strLen';

import toCamelCase from './toCamelCase';

Object.assign( String.prototype, {
  prevZero,
  toUpper,
  breakAll,
  strLen,
  toCamelCase
})
