/**
 * @author flylee
 * 功能：类型检测类
 * 1. 使用 ts 后， 挂载到 prototype 属性和方法， 不能被直接导出。 
 * 2. 现在采用先声明，后使用的原则
 * 3. 现在所有的方法 均挂载到 class2type 实例上，与小程序中的 class2type 有较大区别
 */
import dataTypes from './constant';

class Class2type{

	public getType: Function;
	public isEmptyObject: Function;
	public isBoolean: Function;
	public isArray: Function;
	public isNumber: Function;
	public isString: Function;
	public isUndefined: Function;
	public isNull: Function;
	public isFunction: Function;
	public isDate: Function;
	public isRegExp: Function;
	public isObject: Function;

	constructor(){

		/**
		 * 功能：获取数据类型
		 * 参数：
		 * 	1. opt, 数据对象; 
		 *  2. shift, 切换类型大小写, 默认值 false 首字母大写
		 * 返回值：数据类型
		 */
		this.getType = ( opt: any, shift: boolean = false ): string => {
			const typeStr = Object.prototype.toString.call( opt ).replace( /^\[object\s+(.+)\]$/, '$1' );
			return shift ? typeStr.toLowerCase() : typeStr
		}

		/**
		 * 功能：检测数据是否为 空对象
		 * 参数：
		 * 	1. opt, 数据对象; 
		 */
		this.isEmptyObject = ( params: any ): boolean => {
			if( this.getType( params ) === 'Object' ){
				return Object.keys( params ).length == 0
			}
			return false;
		}

		/**
		 * 功能：检测数据是布尔值
		 * 参数：
		 * 	1. opt, 数据对象;
		 * 下同 
		 */
		this.isBoolean = ( opt: any ): boolean => {
			console.log( this );
			return this.getType( opt, true ) === 'boolean'
		}

		this.isArray = ( opt: any ): boolean => {
			return this.getType( opt, true ) === 'array'
		}

		this.isNumber = ( opt: any ): boolean => {
			return this.getType( opt, true ) === 'number'
		}

		this.isString = ( opt: any ) => {
			return this.getType( opt, true ) === 'string'
		}

		this.isUndefined = ( opt: any ) => {
			return this.getType( opt, true ) === 'undefined'
		}

		this.isNull = ( opt: any ): boolean => {
			return this.getType( opt, true ) === 'null'
		}

		this.isFunction = ( opt: any ): boolean => {
			return this.getType( opt, true ) === 'function'
		}

		this.isDate = ( opt: any ): boolean => {
			return this.getType( opt, true ) === 'date'
		}

		this.isRegExp = ( opt: any ): boolean => {
			return this.getType( opt, true ) === 'regExp'
		}

		this.isObject = ( opt: any ): boolean => {
			return this.getType( opt, true ) === 'object'
		}

	}
}

export const class2type = new Class2type();
export const { 
	isBoolean,
	isNumber,
	isString,
	isUndefined,
	isNull,
	isFunction,
	isArray,
	isDate,
	isRegExp,
	isObject,
	getType,
	isEmptyObject 
} = class2type;
