/**
 * @author lantian
 * 功能：过滤出现 null的数据
 * 参数：
 *  1. data: 检测数据
 *  2. flag：true, 开头 null, false, 尾部 null 
 */
import { isUndefined } from 'tools';
export const filterRenderNull = (data: string, flag?: boolean ) => {
  if( isUndefined( flag ) ){
    data = /null/.test( data ) ? '' : data;
  }else if( flag === true ){
    data = /^null/.test( data ) ? '' : data;
  }else if( flag === false ){
   data = /null$/.test( data ) ? '' : data;
  }
  return data;
}