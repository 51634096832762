// 居间机构平台路由
const agency = [
  // 线索管理
  // {
  //   path: 'clue/clueOpenSea',
  //   name: 'clueOpenSea',
  //   component: () => import('@v/clue/agency/clueOpenSea.vue'),
  //   meta: {
  //     title: '线索公海',
  //     activeUrl: '/clue/clueOpenSea',
  //   },
  // },
  // {
  //   path: 'clue/salesLeads',
  //   name: 'salesLeads',
  //   component: () => import('@v/clue/agency/salesLeads.vue'),//原（已分配）页面改为（销售线索)
  //   meta: {
  //     title: '销售线索',
  //     activeUrl: '/clue/salesLeads',
  //   },
  // },
  // {
  //   path: 'clue/ICreated',
  //   name: 'ICreated',
  //   component: () => import('@v/clue/agency/ICreated.vue'),
  //   meta: {
  //     title: '我创建的',
  //     activeUrl: '/clue/ICreated',
  //     initNav3:true
  //   },
  // },
  // {
  //   path: 'clue/SubCreated',
  //   name: 'SubCreated',
  //   component: () => import('@v/clue/agency/SubCreated.vue'),
  //   meta: {
  //     title: '下属创建的',
  //     activeUrl: '/clue/SubCreated',
  //   },
  // },
  {
    path: 'clue/clueOpenSea',
    name: 'clueOpenSea',
    component: () => import('@v/clueV3/agency/clueOpenSea.vue'),
    meta: {
      title: '线索公海',
      activeUrl: '/clue/clueOpenSea',
    },
  },
  {
    path: 'clue/waitDistribution',
    name: 'waitDistribution',
    component: () => import('@v/clue/agency/waitDistribution.vue'),
    meta: {
      title: '待分配',
      activeUrl: '/clue/waitDistribution',
    },
  },
  {
    path: 'clue/salesLeads',
    name: 'salesLeads',
    component: () => import('@v/clueV3/agency/salesLeads.vue'),
    meta: {
      title: '销售线索',
      activeUrl: '/clue/salesLeads',
    },
  },
  {
    path: 'clue/allocated',
    name: 'allocated',
    component: () => import('@v/clue/agency/allocated.vue'),
    meta: {
      title: '已分配',
      activeUrl: '/clue/allocated',
    },
  },
  {
    path: 'clue/ICreated',
    name: 'ICreated',
    component: () => import('@v/clueV3/agency/ICreated.vue'),
    meta: {
      title: '我创建的',
      activeUrl: '/clue/ICreated',
      initNav3:true
    },
  },
  {
    path: 'clue/SubCreated',
    name: 'SubCreated',
    component: () => import('@v/clueV3/agency/SubCreated.vue'),
    meta: {
      title: '下属创建的',
      activeUrl: '/clue/SubCreated',
    },
  },
  // 商机管理
  // {
  //   path: 'business/BFollowUp',
  //   name: 'BFollowUp',
  //   component: () => import('@v/business/agency/BFollowUp.vue'),
  //   meta: {
  //     title: '我跟进的',
  //     activeUrl: '/business/BFollowUp',
  //   },
  // },
  // {
  //   path: 'business/BAssist',
  //   name: 'BAssist',
  //   component: () => import('@v/business/agency/BAssist.vue'),
  //   meta: {
  //     title: '我协助的',
  //     activeUrl: '/business/BAssist',
  //   },
  // },
  // {
  //   path: 'business/BExternal',
  //   name: 'BExternal',
  //   component: () => import('@v/business/agency/BExternal.vue'),
  //   meta: {
  //     title: '外部跟进',
  //     activeUrl: '/business/BExternal',
  //   },
  // },
  // 订单管理
  // {
  //   path: 'order/OFollowUp',
  //   name: 'OFollowUp',
  //   component: () => import('@v/order/agency/OFollowUp.vue'),
  //   meta: {
  //     title: '我跟进的',
  //     activeUrl: '/order/OFollowUp',
  //   },
  // },
  // {
  //   path: 'order/OAssist',
  //   name: 'OAssist',
  //   component: () => import('@v/order/agency/OAssist.vue'),
  //   meta: {
  //     title: '我协助的',
  //     activeUrl: '/order/OAssist',
  //   },
  // },
  // {
  //   path: 'order/OExternal',
  //   name: 'OExternal',
  //   component: () => import('@v/order/agency/OExternal.vue'),
  //   meta: {
  //     title: '外部跟进',
  //     activeUrl: '/order/OExternal',
  //   },
  // },
  // 管理中心
  {
    path: 'management/agencyStaffManagement',
    name: 'agencyStaffManagement',
    component: () => import('@v/management/agency/staffManagement/staffManagement'),
    meta: {
      title: '员工管理',
      activeUrl: '/management/agencyStaffManagement',
    },
    children: [
      {
        path: 'cxqwStaff',
        name: 'cxqwStaff',
        component: () => import('@v/management/agency/staffManagement/cxqw/cxqwStaff'),
      },
      {
        path: 'cxqwDepartment',
        name: 'cxqwDepartment',
        component: () => import('@v/management/agency/staffManagement/cxqw/cxqwDepartment'),
      },
      {
        path: 'dlqwStaff',
        name: 'dlqwStaff',
        component: () => import('@v/management/agency/staffManagement/dlqw/dlqwStaff'),
      },
      {
        path: 'dlqwDepartment',
        name: 'dlqwDepartment',
        component: () => import('@v/management/agency/staffManagement/dlqw/dlqwDepartmentList'),
      },
    ]
  },
  {
    path: 'management/settingManagement',
    name: 'settingManagement',
    component: () => import('@v/management/agency/settingManagement/settingManagement'),
    meta: {
      title: '配置管理',
      activeUrl: '/management/settingManagement',
    },
  },
  {
    path: 'management/inherit',
    name: 'inherit',
    component: () => import('@v/management/zyy/inherit.vue'),
    meta: {
      isThreeNav: true,
      title: '离职继承',
      activeUrl: '/departureTransfer',
    },
  },
  {
    path: 'management/allocateInfo',
    name: 'allocateInfo',
    component: () => import('@v/management/zyy/allocateInfo.vue'),
    meta: {
      isThreeNav: true,
      title: '已分配商机/订单',
      activeUrl: '/departureTransfer',
    },
  },
  {
    path: 'management/allocatePartner',
    name: 'allocatePartner',
    component: () => import('@v/management/zyy/allocatePartner.vue'),
    meta: {
      isThreeNav: true,
      title: '已分配好友',
      activeUrl: '/departureTransfer',
    },
  },
  {
    path: 'management/incumbency',
    name: 'incumbency',
    component: () => import('@v/management/zyy/inherit.vue'),
    meta: {
      isThreeNav: true,
      title: '在职继承',
      activeUrl: '/incumbencyTransfer',
    },
  },
  {
    path: 'management/INallocateInfo',
    name: 'INallocateInfo',
    component: () => import('@v/management/zyy/allocateInfo.vue'),
    meta: {
      isThreeNav: true,
      title: '已分配商机/订单',
      activeUrl: '/incumbencyTransfer',
    },
  },
  {
    path: 'management/INallocatePartner',
    name: 'INallocatePartner',
    component: () => import('@v/management/zyy/allocatePartner.vue'),
    meta: {
      isThreeNav: true,
      title: '已分配好友',
      activeUrl: '/incumbencyTransfer',
    },
  },
  {
    path: 'management/source',
    name: 'source',
    component: () => import('@v/management/agency/source/source.vue'),
    meta: {
      isThreeNav: true,
      title: '资源管理',
      activeUrl: '/sourceManagement',
    },
  },
  {
    path: 'management/callingAgency',
    name: 'calling',
    component: () => import('@v/management/agency/calling/calling.vue'),
    meta: {
      isThreeNav: true,
      title: '通话记录',
      activeUrl: '/callingManagement',
    },
  },
  {
    path: 'management/msgAgency',
    name: 'msg',
    component: () => import('@v/management/agency/msg/msg.vue'),
    meta: {
      isThreeNav: true,
      title: '短信记录',
      activeUrl: '/msgManagement',
    },
  },
  {
    path: 'management/clueDistribute',
    name: 'clueDistribute',
    component: () => import('@v/management/agency/clueDistribute/index.vue'),
    meta: {
      isThreeNav: true,
      title: '线索自动分配',
      activeUrl: '/clueDistribute',
    },
  },

  // 线索、商机、订单详情
  {
    path: 'baseDetails/baseInfo',
    name: 'baseInfo',
    component: () => import('@v/baseDetails/base.vue'),
    meta: {
      details: true
    },
  },
  // {
  //   path: 'baseDetails/list',
  //   name: 'baseInfo',
  //   component: () => import('@v/baseDetails/list.vue'),
  //   meta: {
  //     details: true
  //   },
  // },
  {
    path: '/board',
    name: 'board',
    component: () => import('@v/board/base.vue'),
    meta: {
      title: '工作看板',
    },
  },
  // 投放清单
  {
    path: 'launch/Aclues',
    name: 'launchClues',
    component: () => import('@v/launch/clue/AClue.vue'),
    meta: {
      isThreeNav: true,
      title: '线索',
      activeUrl: '/launchDetail',
    },
  },
  // 综合流量清单
  {
    path: 'launch/synthesisBetween',
    name: 'synthesisBetween',
    component: () => import('@v/launch/clue/synthesisBetween.vue'),
    meta: {
      isThreeNav: true,
      title: '线索',
      activeUrl: '/launchDetail',
    },
  },
  // {
  //   path: 'launch/Abusiness',
  //   name: 'launchBusiness',
  //   component: () => import('@v/launch/business/ABusiness.vue'),
  //   meta: {
  //     isThreeNav: true,
  //     title: '商机',
  //     activeUrl: '/launchDetail',
  //   },
  // },
  // {
  //   path: 'launch/Aorders',
  //   name: 'launchOrders',
  //   component: () => import('@v/launch/order/AOrder.vue'),
  //   meta: {
  //     isThreeNav: true,
  //     title: '订单',
  //     activeUrl: '/launchDetail',
  //   },
  // },
  // 批量导入 导入历史
  {
    path: 'upload/base',
    name: 'upload',
    component: () => import('@v/upload/base.vue'),
    meta: {
      isThreeNav: true,
      title: '批量导入',
      activeUrl: '/upload',
    },
  },
  {
    path: 'upload/history',
    name: 'upload',
    component: () => import('@v/upload/history.vue'),
    meta: {
      isThreeNav: true,
      title: '批量导入',
      activeUrl: '/upload',
    },
  },
  // BI 统计报表
  {
    path: "biStatisForm/statisticAnaEmp",
    name: "statisticAnaEmp",
    component: () => import("@v/biState/agency/statisticAnaEmp.vue"),
    meta: {
      isThreeNav: true,
      title: "客户统计分析-员工统计",
      activeUrl: "/statisticAna",
    },
  },
  {
    path: "biStatisForm/statisticAnaDep",
    name: "statisticAnaDep",
    component: () => import("@v/biState/agency/statisticAnaDep.vue"),
    meta: {
      isThreeNav: true,
      title: "客户统计分析-部门统计",
      activeUrl: "/statisticAna",
    },
  },
  {
    path: "biStatisForm/followUpAnaEmp",
    name: "followUpAnaEmp",
    component: () => import("@v/biState/agency/followUpAnaEmp.vue"),
    meta: {
      isThreeNav: true,
      title: "客户跟进分析-员工统计",
      activeUrl: "/followUpAna",
    },
  },
  {
    path: "biStatisForm/followUpAnaDep",
    name: "followUpAnaDep",
    component: () => import("@v/biState/agency/followUpAnaDep.vue"),
    meta: {
      isThreeNav: true,
      title: "客户跟进分析-部门统计",
      activeUrl: "/followUpAna",
    },
  },
];
export default agency;
