/**
 * @author lantian
 * 功能： 格式化服务状态, flow
 * 参数：
 *  1. index<number>: 服务节点列表索引
 *  2. activeIndex<number>: 服务节段当前活动节点
 *  3. statusCode<string>: 当前服务状态
 */
import { ENUM } from 'conf';
const { SERVICE_STATUS } = ENUM;
export const handleStatus = ( index: number, activeIndex: number, statusCode: string ): string => {
  let ret: string = 'undo';
  if( index < activeIndex ){
    ret = 'pass';
  }else if( index === activeIndex ) {
    // 判断是否为终止状态
    ret = SERVICE_STATUS[ '服务终止' ] === statusCode ? 'fail' : 'pass'
  }else {
    ret = 'undo'
  }
  return ret;
}