/**
 * @author lantian
 * @desc 线索流转状态，只有居间侧才能创建线索
 */
export enum CUSTOMER_TYPE {
  HOUSE = '房抵',
  CAR = '车抵',
  CREDIT = '信贷',
  GJJ = '公积金',
  QYD = '企业贷',
  房抵 = HOUSE,
  车抵 = CAR,
  信贷 = CREDIT,
  公积金 = GJJ,
  企业贷 = QYD
}