/**
 * 功能：sClueDetail getter 模块
 * 在 钩子函数中使用 
 * const { getters } = useStore()
 * getters[ 'sClueDetail/serveStatus' ] 
 */
import { ENUM } from 'conf';
import { handleStatus, updateService } from './tools';
const { SERVICE_STATUS, CLUE_DICT } = ENUM;

/**
 * 功能： 服务状态数据格式化处理
 * servicePhase: 当前服务阶段
 * serviceStatusCode：当前服务状态
 */
export const serveStatus = ( state: any, getters: any, rootState: any, oo: any ) => {
  const { clueDict, info: { servicePhase, serviceStatusCode, belongType } } = state;
  const { userInfo: { isPlatform } } = rootState;
  const list = clueDict[ CLUE_DICT[ '服务阶段' ] ].sort( ( a: any, b: any ) => a.orderIndex - b.orderIndex );
  const activeIndex = list.findIndex( ( item: any ) => item._value === servicePhase );

  // 服务阶段 flow
  const flowList = list.map( ( item: any, index: number ) => ({ ...item, _status: handleStatus( index, activeIndex, serviceStatusCode ) }));

  // 当前服务
  const active = list[ activeIndex ];
  const next = list[ activeIndex + 1 ] || {};
  const stop = SERVICE_STATUS[ '服务终止' ] === serviceStatusCode;
  
  // 若果是平台分发商机，只能回退到已转商机，否则可以回退到新线索
  const isPlatformSolt = isPlatform && belongType === '平台分发商机';

  console.log({
    list,
    next,
    servicePhase,
    activeIndex,
    serviceStatusCode,
    active,
    isPlatformSolt, // 是平台分发 且 当前状态不是 已转商机状态
  }, '--------------serveStatus------------' )
  
  const updateList = updateService({
    stop,
    active,
    next,
    activeIndex
  });

  return {
    flowList,
    active, // 当前活跃 flow 节点
    activeIndex, // 当前活跃节点的索引
    stop, // 服务终止
    updateList, // 服务阶段变更节点渲染
    next, // 下一服务节段节点
    isPlatformSolt, // 是否为平台分发商机
    servicePhase,
    serviceStatusCode
  }
}