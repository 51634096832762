/**
 * @author lantian
 * 功能：文件类型处理
 */

import { isNull } from 'tools';

class File2 {
  private imageMaxSize: number = 20;
  private fileMaxSize: number = 80;
  private imageList = [ 'png', 'jpg', 'jpeg' ];
  private fileList = [ 'doc', 'docx', 'pdf', 'zip', 'rar', 'ppt', 'pptx', 'xlsx', 'xls' ];
  public constructor(){
  }
  /**
   * 功能：获取文件类型 
   * 参数：file<File>
   * 返回值：
   */
  public getType(file: File): string{
    const { name } = file;
    const extName = name.split( '.' ).pop() || '';
    return extName
  }

  /**
   * 功能：获取图片大小
   * 参数：file<File>
   * 返回值：文件 Mb
   */
  public getSize( file: File ): number{
    const { size } = file;
    const bit = 1024;
    const val = ( size / bit / bit ) as any;
    return  val.toFixed( 2 );
  }

  /**
   * 功能：文件是否为图片
   */
  public isImage( file: File): boolean{
    const fileType = this.getType( file ) || '';
    return this.imageList.includes( fileType );
  }

  /**
   * 功能：检测是否为图片其他文件类型
   */
  public isFile(file: File){
    const fileType = this.getType( file ) || '';
    return this.fileList.includes( fileType );
  }

  /**
   * 功能：校验文件类型
   */
  public verifyType(file: File): boolean{
    const isImage = this.isImage( file );
    const isFile = this.isFile( file );
    return isImage || isFile || false;
  }

  /**
   * 功能：文件尺寸校验
   * 参数：file<File>
   * 返回值：boolean
   */
  public verifySize(file: File): boolean{
    const size = this.getSize( file );
    if( this.isImage( file ) ){
      return size < this.imageMaxSize;
    }else if( this.isFile( file )){
      return size < this.fileMaxSize;
    }else {
      return false;
    }
  }

}


export const file2 = new File2();