
/**
 * @author lantian
 * @desc 功能：日期处理，实现对日期的格式化，增减，几日前后，一周前后，几天前后，几个月前后，几年前后等操作, 支持链式调用
 * @ps 建议提 bug， 满足更多的功能需求
 * 参数： 
 *  1. date<date>: 日期对象
 *  2. fmt<string>: 数据输出的格式
 */
import { DateTypeStr, DateTypeParams } from './index.d'
import { isDate, getType, isString } from 'class2type';
import { func } from 'tools';

export class SuperDate{

  private date: any;

  private fmt: string;

  private map = {
    Y: 'Year',
    M: 'Month',
    D: 'Day',
    W: 'Week',
    h: 'hour',
    m: 'minute',
    s: 'second',
  }

  constructor( date?: DateTypeParams, fmt='YYYY-MM-DD hh:mm:ss' ){
    // date 数据校验
    const type = getType( date, true ); 
    switch( type ){
      case 'undefined': this.date = new Date(); break;
      case 'string': this.date = new Date( ( <string>date ).replace( /\-/g, '/' ) ); break;
      case 'number': this.date = new Date( (<number>date) ); break;
      case 'date': this.date = date; break;
      default:  throw new Error( 'paramter is wrong' )
    }
    this.fmt = fmt;
  }
  
  /**
   * 功能：格式化
   */
  public format( fmt: string ){

    fmt = fmt || this.fmt;

    const { Y, M, D, h, m, s } = this.splitDate( this.date );

    if( (/(Y+)/.test( fmt ) ) ){
      const $1 = RegExp.$1;

      fmt = fmt.replace( $1, String( Y ).substr( 4 - $1.length ) );
      
      const data = /m|h|s/.test( fmt ) ? {
        "M+": M,
        "D+": D,
        "h+": h,
        "m+": m,
        "s+": s
      } : {
        "M+": M,
        "D+": D
      }

      func.loop( data, ( val: string, key: string ) => {
        if( new RegExp(`(${ val })`).test( fmt ) ){
          const $1 = RegExp.$1;
          fmt = fmt.replace( $1, $1.length === 1 ? key : ( "00" + key ).substr( -2 )  );
        }
      });
      
      return ( fmt || '' ).trim();
    }
  }
  
  /**
   * 功能： 核心方法，获取新日期
   * 参数：
   *  1. type<string>：日期加减类型
   *  2. num<number>: 增量
   * 返回值：实例
   */
  private _getNewDate( type: DateTypeStr, num: number ){
    let { Y, M, D, h, m, s } = this.splitDate( this.date );
    switch( type ){
      case 'year': Y = Y + num; break;
      case 'month': M = M + num; break;
      case 'day': D = D + num; break;
      case 'hour': h = h + num; break;
      case 'minute': m = m + num; break;
      case 'second': s = s + num; break;
    }
    this.date = new Date( Y, M-1, D, h, m );
    return this;
  }

  /**
   * 功能： 将传入的 date 转成 json
   */
  toJSON(){
    const { date } = this
    const Y = date.getFullYear();
    const M = date.getMonth() + 1;
    const D = date.getDate();
    const W = date.getDay(); // 大写

    const h = date.getHours();
    const m = date.getMinutes();
    const s = date.getSeconds();
    const ms = date.getMilliseconds();
    return { Y, M, D, W, h, m, s, ms }
  }
 
  /**
   * 功能：检测是否为闰年
   * 参数：
   *  1. year: 年份
   * 返回值： true, 闰年， false, 平年
   */
  static isLeapYear( year: number | string ): boolean{

    if( !year ) throw new Error( 'paramter year is falsy' )

    if( isString( year ) ){
      year = Number( year )
    }

    year = year as number;
    
    return year % 4 == 0 && year % 400 != 0;

  }

  /**
   * 功能：比较日期，并返回日期差
   */
  static diff(){

  }

  /**
   * 功能：检测日期是否有效
   */
  isValidDate(){
    
  } 

   /**
   * 功能： 获取当前日期的毫米数
   */
  now(){
    return this.date.getTime();
  }
  /**
   * 功能： 获取指定月份的天数
   */
  public getMaxDay( date: Date ){
    const { Y, M } = this.splitDate( date || this.date );
    return new Date( Y, M, 0 ).getDate();
  }
  /**
   * 功能： 分割日期
   * 参数：
   *  1. date<date>
   * 返回值：array
   */
  public splitDate( date: Date ){

    if( !isDate( date ) ){
      throw `Expected an date Object, but got a ${ getType( date ) }`
    }

    const Y = date.getFullYear();
    const M = date.getMonth() + 1;
    const D = date.getDate();
    const W = date.getDay(); // 大写

    const h = date.getHours();
    const m = date.getMinutes();
    const s = date.getSeconds();
    const ms = date.getMilliseconds();
    return { Y, M, D, W, h, m, s, ms }
  }
  /**
   * 前几小时
   */
  beforeMinutes( num: number ){
    return this._getNewDate( 'minute', -num )
  }
  /**
   * 前几小时
   */
  beforeHours( num: number ){
    return this._getNewDate( 'hour', -num )
  }
  /**
   * 功能：过去当前日期的前几天
   * 参数：
   *  1. num<number>: 数字
   * 返回值：实例
   */
  beforeDays( num: number ){
    return this._getNewDate( 'day', -num )
  }
  /**
   * 功能：几周前
   */
  beforeWeeks( num: number ){
    return this._getNewDate( 'day', -num * 7 )
  }
  /**
   * 前些月
   */
  beforeMonths( num: number ){
    return this._getNewDate( 'month', -num );
  }
  /**
   * 前些年 
   */
  beforeYears( num: number ){
    return this._getNewDate( 'year', -num );
  }
  /**
   *  获取当前的时间的前些天、月、年、周
   */
  before( num: number, type: DateTypeStr="day" ){
    const map = ( this.map as any ) [ type.toUpper( true ) ]

    return (<any>this)[ `before${ map }s`]( num ); 
  }
  /**
   * 几年后
   */
  afterYears( num: number ){
    return this._getNewDate( 'year', num );
  }
  /**
   * 几月后
   */
  afterMonths( num: number ){
    return this._getNewDate( 'month', num );
  }
  /**
   * 功能：几周后
   */
  afterWeeks( num: number ){
    return this._getNewDate( 'day', num * 7 )
  }
  /**
   * 几天后
   */
  afterDays( num: number ){
    return this._getNewDate( 'day', num );
  }
  /**
   * 后几小时后
   */
  afterHours( num: number ){
    return this._getNewDate( 'hour', -num )
  }
  /**
   * 后几分钟
   */
  afterMinutes( num: number ){
    return this._getNewDate( 'minute', num )
  }
  /**
   *  获取当前的时间的之后些天、月、年、周
   */
  after( num: number, type: DateTypeStr="day" ){
    const map = ( this.map as any ) [ type.toUpper( true ) ]
    return (<any>this)[ `after${ map }s`]( +num );
  }
  /**
   * 功能： 日期加
   * num: 自然数
   * type: day, month, year, week, hour, minute
   */
  add( num: number, type: DateTypeStr="day" ){
    return this.after( num, type );
  }
  /**
   * 功能： 日期减法处理
   */
  sub( num: number, type: DateTypeStr="day" ){
    return this.before( num, type );
  }
  /**
   * 功能：将 superDate 转成 date 对象
   */
  toDate(){
    return this.date;
  }
  /**
   * 功能：获取 timestramp
   */
  valueOf(){
    return this.date.getTime();
  }
}