/**
 * @author lantian
 * @desc 线索服务阶段
 */
 export enum SERVICE_PHASE {
  NEW = '新线索',
  TRANSFERRED_BUSINESS = '已转商机',
  ORG_INTERVIEW = '已上门',
  SIGNED = '已签约',
  APPROVED = '已批复',
  LOAN = '已放款',
  SUCCESS = '已成单',

  新线索 = 'NEW',
  已转商机 = 'TRANSFERRED_BUSINESS',
  已上门 = 'ORG_INTERVIEW',
  已签约 = 'SIGNED',
  已批复 = 'APPROVED',
  已放款 = 'LOAN',
  已成单 = 'SUCCESS',
}