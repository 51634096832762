import { ElMessageBox } from 'element-plus';
import type { Action } from 'element-plus'
/**
 * @author lantian
 * 功能： alert 弹窗
 * 参数：message 消息内容，title，标题
 * 返回值：Promise<Retuns|void> 
 */
export const alert = (message: string | any, title: string="温馨提示" ): Promise<string> => {
  return new Promise( ( resolve ) => {
    ElMessageBox.alert( message, title, {
      confirmButtonText: '我知道了',
      callback: (action: Action) => {
        resolve( action );
      },
    })
  })
}
