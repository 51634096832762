/**
 * @author lantian
 * @desc: 客户标签
 */

import { $postData, $getData } from "@/axios";

// 获取全部标签
export const getLabelInfo = ( data: any={} ): Promise<{}> => $postData( '/sys/label/getLabelInfo', data );

// 线索打标签
export const labelling = ( data: any={} ): Promise<{}> => $postData( '/sys/label/labelling', data );

// 根据线索查标签
export const getLabelDetailByClueId = ( data: any={} ): Promise<{}> => $postData( '/sys/label/getLabelDetailByClueId', data );

