/**
 * @author lantian
 * @desc 还款方式
 */
export enum REPAYMENT_TYPE {
  FIRST_INTEREST = '先息后本',
  EQUAL_INTEREST = '等额本息',
  ANY_TIME = '随借随还',
  DEBJ = '等额本金',
  DBDX = '等本等息',

  先息后本 = 'FIRST_INTEREST',
  等额本息 = 'EQUAL_INTEREST',
  随借随还 = 'ANY_TIME',
  等额本金 = 'DEBJ',
  等本等息 = 'DBDX',
}
