
/**
 *  @author lantian
 *  @desc 变更服务按钮控制
 */
import { ENUM } from 'conf';

const { SERVICE_PHASE, SERVICE_STATUS } = ENUM;
const serviceInfoModify: any[] = [
  { _value: 'SIGNED2', _label: '变更合同信息' },
  { _value: 'APPROVED2', _label: '变更批复信息' },
  { _value: 'LOAN2', _label: '变更放款信息' },
  { _value: 'SUCCESS2', _label: '变更结算信息' }
];
const STOP_TXT = '服务终止';

/**
 * 功能：处理文案信息
 * 参数：
 *  1. prefix<string>: 前缀
 *  2. phase<string>: 服务阶段状态
 */
const handleTxt = ( prefix: string, phase: string,): string => prefix + `「${ phase }」`;

/**
 * 功能：服务状态变更操作按钮逻辑处理
 * 参数：
 *  1. index<number>: 服务节点列表索引
 *  2. activeIndex<number>: 服务节段当前活动节点
 *  3. statusCode<string>: 当前服务状态
 */
export const updateService = (opt: any) => {
  const SUCCESS = SERVICE_PHASE[ '已成单' ];
  const { stop, next, active } = opt;

  // 服务终止
  // 1. 当前阶段「新线索·服务终止」，推进至「已转商机」，恢复至「新线索」
  // 2. 当前阶段「已转商机·服务终止」，推进至「已上门」，恢复至「已转商机」
  // 3. 当前阶段「已上门·服务终止」，推进至「已签约」，恢复至「已上门」
  // 4. 当前阶段「已签约·服务终止」，推进至「已批复」，恢复至「已签约」
  // 5. 当前阶段「已批复·服务终止」，推进至「已放款」，恢复至「已批复」
  // 6. 当前阶段「已放款·服务终止」，推进至「已成单」，恢复至「已放款」
  // 7. 当前阶段「已成单」，变更合同信息，变更批复信息，变更放款信息，变更结算信息

  // 服务进行中
  // 1. 当前阶段「新线索」，推进至「已转商机」，服务终止
  // 2. 当前阶段「已转商机」，推进至「已上门」，服务终止
  // 3. 当前阶段「已上门」，推进至「已签约」，服务终止
  // 4. 当前阶段「已签约」，推进至「已批复」，服务终止，变更合同 1
  // 5. 当前阶段「已批复」，推进至「已放款」，服务终止，变更合同信息，变更批复信息 2
  // 6. 当前阶段「已放款」，推进至「已成单」，服务终止，变更合同信息，变更批复信息，变更放款信息 3
  // 7. 当前阶段「已成单」，变更合同信息，变更批复信息，变更放款信息、变更结算信息 4
  if( active ){
    const { _value, _label } = active;
    let sliceIndex = 0;
    if( stop ) { // 服务终止状态
      return _value == SUCCESS ? [
        { _value, _label: handleTxt( '当前阶段', `${ _label }·${ STOP_TXT }` ) },
      ].concat( serviceInfoModify ) : [
        { _value, _label: handleTxt( '当前阶段', `${ _label }·${ STOP_TXT }` ) }, 
        { _value: next._value, _label: handleTxt( '推进至', next._label ) },
        { _value: _value + '2', _label: handleTxt( '恢复至', _label ) }
      ]
    }else{
      const ret: any[] = _value == SUCCESS ? [ 
        { _value, _label: handleTxt( '当前阶段', _label ) }
      ] : [
        { _value, _label: `当前阶段「${ _label }」` },
        { _value: next._value, _label: handleTxt( '推进至', next._label ) },
        { _value: SERVICE_STATUS[ '服务终止' ], _label: STOP_TXT },
      ];

      switch( _label ){
        case '已签约': sliceIndex = 1; break;
        case '已批复': sliceIndex = 2; break;
        case '已放款': sliceIndex = 3; break;
        case '已成单': sliceIndex = 4; break;
      }

      return ret.concat( serviceInfoModify.slice( 0, sliceIndex ) );
    }
  }
}