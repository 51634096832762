/**
 * @author liantian
 * 功能：格式化时间, 后期优化到 superDate 里面的静态方法
 * 参数：
 *  1. time 秒数，或是格式为 '00:00:00' 的字符串
 *  2. toSec：是否 toSec 转成秒
 */
export const formatTime = ( time: number | string, toSec: 'toSec' | any ): string | number => {
  if( toSec === 'toSec' ){
    const times = (<string>time).split( ':' );
    return times.reduce( ( prevTotal, item, index ) => prevTotal + Number( item ) * Math.pow( 60, 2 - index ), 0 );
  }else{
    const hour = Math.floor( <number>time / 60 / 60 % 24 );
    const min =  Math.floor( <number>time / 60 % 60 );
    const sec = Math.floor( <number>time % 60 );
    // 不展示小时位空的情况
    return [ hour, min, sec ].map( item => String( item ).prevZero() ).join( ':' ).replace(/^(00:)/, '');
  }
}