/**
 * @author lantian
 * 功能：过滤 null
 */ 
import { isNull } from 'class2type';
import { loop } from './../loop';
interface Params {
  [key: string]: any;
}

export const filterNull = ( data: Params ): Params => {
  const ret: Params = {};
  loop( data, ( key: string, value: any ) => {
    if( !isNull( value ) ){
      ret[key] = value 
    }
  });
  return ret;
}