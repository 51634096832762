/**
 * @author lantian
 * @desc 线索流转状态，只有居间侧才能创建线索
 */
export enum TURN_TYPE {
  BEGIN="未流转",
  PUSH_PT="推送平台", // 居间侧线索，推送到平台
  PROCESS_CLUE="线索消化", // 内部转商机后
  PROCESS_BUS="商机消化", // 居间侧推平台后，平台推到其他居间侧后
  UN_KNOW="未知",
}