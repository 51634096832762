/**
 * 功能：将数组通过 index 索引 分组
 * 参数： index，num 类型
 * 返回值：array<array>
 */

import { isArray, isNumber, getType } from 'class2type';

export default function( num ){

  if( !isArray( this ) ){
    throw `Expected an array, but got a ${ getType( this ) }`
  }

  if( !isNumber( num ) || num == 0 ) return this;

  const ret = [ ...this ];

  const del = ret.splice( num, ret.length - num ); 

  return [ ret, del ]

}