/**
 * 功能：获取数组第一个元素
 */
import { isArray, getType } from 'class2type';

export default function(){

  if( !isArray( this ) ){
    throw `Expected an array, but got a ${ getType( this ) }`
  }

  return this[0];

}