/**
 * 功能：将数组通过 指定的 key 分组
 * 参数：key<string>, 
 * 返回值：array<array>
 */

import { isArray, getType } from 'class2type';

export default function( key, ){

  if( !isArray( this ) ){
    throw `Expected an array, but got a ${ getType( this ) }`
  }

  let ret = {};
  if( this.length === 0 ) return this;

  this.forEach( item => {
    const curKey = item[ key ];
    ret[ curKey ] = ret[ curKey ] ? [ ...ret[ curKey ], item ]: [ item ];
  });




  return Object.entries( ret ).map( ([ id, list ]) => ({
      gradeId: id,
      gradeName: list[0].gradeName,
      list: list.map( item => ({ ...item.clbum, ...item  })),
    })
  )

}