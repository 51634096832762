import axios from 'axios';
import qs from 'qs';
import * as CONF from 'conf';
import { ElMessageBox, ElMessage } from 'element-plus';
import md5 from 'md5'

const { CancelToken } = axios;
const baseUrl = CONF.VARS.baseURL;
axios.defaults.timeout = 6000000;
axios.defaults.baseURL = baseUrl;
let isShow500 = false;
axios.interceptors.request.use((config) => {
  if (config.method === 'post' && config.data && config.data['Content-Type']) {
    config.headers['Content-Type'] = config.data['Content-Type'];
    delete config.data['Content-Type'];
    config.data = qs.stringify(config.data);
  }
  config.cancelToken = new CancelToken((c) => {
    window.axiosPromiseCancel.push(c);
  });

  //接口请求头上添加签名字段
  const { url } = config;
  const headAddSignature = handleMd5(url);
  config.headers = {
    ...config.headers,
    ...headAddSignature
  }

  return config;
});

axios.interceptors.response.use(
  (response) => {
    if (isShow500) return
    if (response.data.code == 401 || response.data.code == 403) {
      isShow500 = true;
      window.localStorage.clear();
      window.location.href = '#/login';
      setTimeout(() => {
        ElMessageBox.alert('登录超时，请重新登录', '提示', {
          showClose: false,
        }).finally( () => {
          window.location.href = '#/login';
          location.reload();
        })
      },100)
      return;
      
      // isShow500 = true;
      // ElMessageBox.alert(response.data.msg || response.data.message, '提示', {
      //   confirmButtonText: '确定',
      //   customClass: 'nowrap',
      //   callback: () => {
      //     isShow500 = false;
      //     var offset = window.location.href.indexOf("#/login?token=");
      //     var newUrl = window.location.href.substring(0, offset)
      //     //返回大于等于0的整数值，若不包含"Text"则返回"-1。
      //     if (offset > 0) {//只有登录url时，才做跳转
      //       // document.write("包含字符串");
      //       window.location.href = newUrl;
      //     } else {
      //       // document.write("不包含字符串");
      //     }

      //   },
      // });
    } else {
      if ( response.data.code != 200 && response.data.message ) ElMessage.error(`${response.data.message}`)
    }
    return response;
  },
  (error) => {
    if (error.message === undefined) return
    if (!isShow500) {
      isShow500 = true;
      ElMessageBox.alert('网络错误', '提示', {
        confirmButtonText: '确定',
        callback: () => {
          isShow500 = false;
        },
      });
    }
    return Promise.reject(error);
  },
);

const $postData = (url, data, options) => {
  const datas = {
    url,
    data,
    method: 'post',
    headers: {
      token: localStorage.getItem('zt'),
    },
  };
  const params = { ...datas, ...options };
  return axios(params);
};
const $postData1 = (url, data, options) => {
  const datas = {
    url,
    data,
    method: 'post',
    headers: {
      token: localStorage.getItem('zt'),
      'Content-Type': 'audio/mp3'
    },
  };
  const params = { ...datas, ...options };
  return axios(params);
};

const $getData = (url, params) => {
  const datas = {
    url,
    method: 'get',
    params,
    headers: {
      token: localStorage.getItem('zt'),
    },
  };
  return axios(datas);
};

const $blobGetData = (url, params) => {
  const datas = {
    url,
    method: 'get',
    params,
    headers: {
      token: localStorage.getItem('zt'),
    },
    responseType: 'blob',
  };
  return axios(datas);
};

const $blobPostData = (url, data, options) => {
  const datas = {
    url,
    data,
    method: 'post',
    headers: {
      token: localStorage.getItem('zt'),
    },
    responseType: 'blob',
  };
  const params = { ...datas, ...options };
  return axios(params);
};

const $compression = (file, size = 20, device = 4) => {
  if (file[0]) {
    return Promise.all(Array.from(file).map(e => Vue.prototype.$compression(e, size))) // 如果是 file 数组返回 Promise 数组
  } else {
    return new Promise((resolve) => {
      const reader = new FileReader() // 创建 FileReader
      reader.onload = (e) => {
        const { target: { result: src } } = e;
        const fileSize = Number((file.size / 1024).toFixed(2))
        if (fileSize <= size) {
          resolve({ file: file, origin: file, beforeSrc: src, afterSrc: src, beforeKB: fileSize + 'KB', afterKB: fileSize + 'KB', detail: [], quality: null })
        } else {
          const image = new Image() // 创建 img 元素
          image.onload = async () => {
            const canvas = document.createElement('canvas') // 创建 canvas 元素
            canvas.width = image.width
            canvas.height = image.height
            canvas.getContext('2d').fillStyle = "#fff";
            canvas.getContext('2d').fillRect(0, 0, canvas.width, canvas.height);
            canvas.getContext('2d').drawImage(image, 0, 0, image.width, image.height) // 绘制 canvas
            let canvasURL, miniFile
            let L = true
            let quality = 0
            const detail = []
            let start = Date.now()
            for (let i = 1; i <= device; i++) {
              canvasURL = canvas.toDataURL('image/jpeg', L ? (quality += 1 / (2 ** i)) : (quality -= 1 / (2 ** i)))
              const buffer = atob(canvasURL.split(',')[1])
              let length = buffer.length
              const bufferArray = new Uint8Array(new ArrayBuffer(length))
              while (length--) {
                bufferArray[length] = buffer.charCodeAt(length)
              }
              miniFile = new File([bufferArray], file.name, { type: 'image/jpeg' });
              (miniFile.size / 1024) > size ? L = false : L = true
              detail.push({
                quality,
                size: miniFile.size,
                kb: Number((miniFile.size / 1024).toFixed(2)),
                time: Date.now() - start
              })
              start = Date.now()
            }
            resolve({
              detail,
              quality,
              file: miniFile,
              origin: file,
              beforeSrc: src,
              afterSrc: canvasURL,
              beforeKB: Number((file.size / 1024).toFixed(2)),
              afterKB: Number((miniFile.size / 1024).toFixed(2))
            })
          }
          image.src = src
        }
      }
      reader.readAsDataURL(file)
    })
  }
}

// 签名
const handleMd5 = (uri) => {
  const key = 'B@96$p2Lg!';
  const reverseUri = `${uri}`.split('').reverse().join('');
  const timeStp10 = `${new Date().getTime()}`.substring(0, 10);
  const signStr = `${key}${reverseUri}@${timeStp10}`
  const sign = md5(signStr);
  return {
    t: timeStp10,
    sign
  }
}

export {
  $postData, $getData, $blobGetData, $blobPostData, $compression, $postData1
};
