/**
 * @author lantian
 * 功能：线索详情模块状态管理
 */
import * as actions  from './actions';

export default {
  namespaced: true,
  actions,

  state: () => ({
    checkList: [], // 线索标签, 已打标签
    labelInfo: null, // 标签信息
  }),

  mutations: {

    // 线索标签
    mCheckList( state: any, payload: any ){
      state.checkList = payload || [];
    },

    // 标签列表
    mLabelInfo( state: any, payload: any ){
      state.labelInfo = payload;
    }
  },

 
  
}