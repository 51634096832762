/**
 * @author lantian
 * @desc 附件类型
 */
export enum ATTACHMENT_TYPE {
  BUSINESS_LICENSE = '营业执照',
  HOUSE = '房产证', 
  SERVICE_CONTRACT = '服务合同',
  CREDIT = '征信附件',
  IDENTITY_CARD = '身份证',
  OTHER = '其他附件',

  营业执照 = 'BUSINESS_LICENSE',
  房产证 = 'HOUSE', 
  服务合同 = 'SERVICE_CONTRACT',
  征信附件 = 'CREDIT',
  身份证 = 'IDENTITY_CARD',
  其他附件 = 'OTHER',
}