<template>
  <el-config-provider :locale="locale">
    <router-view></router-view>
  </el-config-provider>
  <NotBoundPhone></NotBoundPhone>
</template>
<script>
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import NotBoundPhone from '@c/NotBoundPhone.vue'

export default {
  data() {
    return {
      locale: zhCn
    };
  },
  components: {
    [ElConfigProvider.name]: ElConfigProvider, //添加组件
    NotBoundPhone
  }
};
</script>
<style lang="less">
@import "./assets/css/less/index.less";
@import "./assets/css/common.less";
@import "./assets/iconfont/iconfont.css";
</style>
