/**
 * @author lantian
 * @desc 订单信息
 */
export enum ORDER_STATUS {
  SIGN_CONTRACT = "签订合同",
  PAY_DEPOSIT = "支付定金",
  START_BILL = "做单",
  LOAN = "放款",
  SUCCESS = "成单"
}