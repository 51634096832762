/**
 * @author lantian
 * @desc 客户意向
 */
export enum INTENTION {
  HAS_INTENTION = "有意向",
  NOT_SURE = "未明确",
  NO_INTENTION = "无意向",
  SAME_WORKER = "同行",
  NO_QUALIFICATIONS = "无资质",
  NO_ORG_PROCESS = "无渠道消化",

  有意向 = "HAS_INTENTION",
  未明确 = "NOT_SURE",
  无意向 = "NO_INTENTION",
  同行 = "SAME_WORKER",
  无资质 = "NO_QUALIFICATIONS",
  无渠道消化 = "NO_ORG_PROCESS",
}
