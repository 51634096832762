

import unique from './unique';
import group from './group';
import groupByIndex from './groupByIndex';
import groupByKey from './groupByKey';
import first from './first';
import last from './last';
import padEnd from './padEnd';

Object.assign( Array.prototype, {
  unique,
  group,
  groupByIndex,
  groupByKey,
  first,
  last,
  padEnd,
})
