import { ElMessageBox } from 'element-plus';
/**
 * @author lantian
 * 功能： confirm 弹窗
 * 参数：message 消息内容，title，标题， opt, 其他原生 api 组件参数
 * 返回值：Promise<Retuns|void> 
 */

interface Retuns {
  close: Function;
  action: 'confirm' | 'cancel';
  instance: any
}

export const confirm = ( message: string | any, title: string="温馨提示", opt={} ): Promise<Retuns | void> => {
  const defaultOpt: any = {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    showCancelButton: true,
    type: 'warning',
  };
  return new Promise( (resolve, reject) => {
    ElMessageBox({
      message,
      title,
      ...defaultOpt,
      ...opt,
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true
          resolve({ close: done, action, instance });
        } else {
          done();
        }
      },
    }).catch( () => {
      reject()
    })
  })
}