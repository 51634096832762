/**
 * @author lantian
 * 功能：实现 forin 循环，建议只用来遍历 json
 * 参数：
 *  1. data<object>: forin 循环
 *  2. fn<func>: 回调函数
 * @return：void
 * @desc: 提升循环遍历性能
 */
export const loop = ( data: any, fn: Function ) => {
  for( let key in data ){
    fn( key, data[key], data );
  }
}