/**
 * @author lantian
 * @desc：所有的 mutations 均使用 m 开头
 */
  
  /**
  * 功能：获取线索详情信息
  */
  export const mGetInfo = ( state: any, payload: any ) => {
    state.info = payload;
  }

  /**
  * 功能：获取服务状态信息
  */
  export const mGetServeStatus = ( state: any, payload: Array<any> ) => {
    state.serveStatusList = payload || [];
  }

  // 获取字典信息
  export const mGetClueDict = ( state: any, payload: any ) => {
    state.clueDict = payload || [];
  }

  // 线索标签
  export const mClueTags = ( state: any, payload: any ) => {
    state.clueTags = payload || [];
  }

  // 合同， 已签约信息
  export const mOrderInfo = ( state: any, payload: any ) => {
    state.orderInfo = payload;
  }

  // 批复信息
  export const mReplyInfo = ( state: any, payload: any ) => {
    state.replyInfo = payload;
  }

  // 放款信息
  export const mLoanInfo = ( state: any, payload: any ) => {
    state.loanInfo = payload;
  }

  // 结算信息
  export const mSettlementInfo = ( state: any, payload: any ) => {
    state.settlementInfo = payload;
  }

  // 跟进记录
  export const mRecordList = ( state: any, payload: any ) => {
    state.recordList = payload || [];
  }

  // 跟进记录弹窗控制
  export const mToggleFollowUp = ( state: any, payload: any) => {
    state.toggleFollowUp = payload || [];
  }