/**
 * @author lantian
 * 功能：线索详情模块状态管理
 */
import * as actions  from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state: () => ({
    info: {}, // 详情 信息
    clueDict: {}, // 说数据字典
    clueTags: [], // 线索标签
    orderInfo: null, // 合同信息
    replyInfo: null, // 批复信息 
    loanInfo: null, // 变更放款信息
    settlementInfo: null, // 结算
    recordList: [], // 跟进记录
    // 打开/关闭
    toggleFollowUp: {
      visible: false, // 默认关闭
      servicePhase: '', // 额外值
    },
  })
}