/**
 * @author lantian
 * @desc: 线索详情模块
 */
import { URL } from 'tools';
import { $postData, $getData } from "@/axios";

// 获取标签列表
export const getTagForFilter = ( data: any ): Promise<{}> => $postData( '/sys/label/getTagForFilter', data );

// 数据字典
export const clueDict = ( data: any ): Promise<{}> => $postData( '/api/clue/dict', data );

// 获取抄送人列表
export const copyUserList = ( clueId: string ): Promise<{}> => $getData( '/api/work/panel/copyUser/list', { clueId });

// 人员选择器
export const orgList = ( data: any ): Promise<{}> => $postData( '/sys/org/list', data );

// 获取线索详情 平台
export const getById = (clueId: string): Promise<{}> => $getData( `/api/clue/getById?clueId=${clueId}`);

// 获取线索详情 居间侧
export const getClueByIdV3 = (clueId: string): Promise<{}> => $getData( `/api/clue/getClueByIdV3?clueId=${clueId}` );

// 跟进记录表单提交
export const createV4 = (data: any): Promise<{}> => $postData( '/api/clue/followRecord/createV4', data );


// 订单信息，合同信息
export const order = (orderId: string): Promise<{}> => $getData( `/api/clue/order/${orderId}` );

// 批复信息获取
export const reply = (orderId: string): Promise<{}> => $getData( `/api/order/reply/orderId/${orderId}` );

// 结算信息获取
export const settlement = (orderId: string): Promise<{}> => $getData( `/api/order/settlement/orderId/${orderId}` );

// 放款信息获取
export const loan = (orderId: string): Promise<{}> => $getData( `/api/order/loan/orderId/${orderId}` );

// 跟进记录查询
export const followRecord = (data: any): Promise<{}> => $postData( `/api/clue/followRecord/listV4`, data );


// 放款信息获取
export const qualifications = (orderId: string): Promise<{}> => $getData( `/api/order/loan/orderId/${orderId}` );


// 关联线索
export const relation = (data: any): Promise<{}> => $getData( `/api/clue/relation/list?${ URL.join( data ) }` )







