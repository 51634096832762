/**
 * @author lantian
 * @desc 服务状态
 */
export enum SERVICE_STATUS {
  TO_FOLLOW = '待跟进',
  IN_FOLLOW = '已跟进',
  TERMINATION = '服务终止',
  SETTLED = '已结算', 

  待跟进 = 'TO_FOLLOW',
  已跟进 = 'IN_FOLLOW',
  服务终止 = 'TERMINATION',
  已结算 = 'SETTLED',
}