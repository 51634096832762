/**
 * @author liantian
 * 功能：将字符串格式成驼峰形式
 * 参数：flag 首字母是否大写, 默认大写
 */
import { isString } from 'class2type';
export default function( flag=true ){

  if( !isString( this ) ){
    throw `Expected a string, but got a ${ getType( this ) }`
  }

  const ret = this.toLowerCase().replace(/\_(\w)/g, (a, b) => b.toUpperCase() );
  if( flag ){
    return ret.replace(/(^\w)/, (a, b) => b.toUpperCase() );
  }
  return ret;
}