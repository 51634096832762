
/**
 * @author 蓝天
 * @desc 功能：处理 url 参数相关, 不处理多个 ? 的情况， 如果出现多个? 请使用 base64 加密后处理
 */

import { func } from 'tools';
import { isString, isObject, getType } from 'class2type';

export const URL = Object.create({
  
  /**
   * 功能：将 query 分割成 json 对象
   * 参数：query<string>
   * 返回值：object
   */
  split( query: any ){
    const ret: any = {};
    query = query.split( '&' );
    query.forEach( (el: string) => {
      const [ key, val ] = el.split( '=' );
      ret[ key ] = val;
    });
    return ret;
  },

  /**
   * 功能：将 json 对象 序列化
   * 参数：data<object>
   * 返回值：string
   */
  join( data: any ){
    const arr:any[] = [];
    func.loop( data, ( key: string, val: any ) => {
      arr.push( `${ key }=${ val }` );
    })
    return arr.join( '&' );
  },

  /**
   * 功能：获取 url 参数
   * 参数：
   *  1. url<string>: 地址
   *  2. mid<string>: 指定的参数 key
   * 返回值：string|null
   */
  getParams( url='', mid: string ){
    url = decodeURIComponent( url );
    const [ path, query ] = url.split( '?' );
    
    if( !query ) return null
    
    const regx = new RegExp("(^|&)" + mid + "=([^&]*)(&|$)");
    const r = query.match( regx );
    if ( r != null ) 
      return decodeURIComponent( r[2] ); 
    return null;
  },

  /**
   * 功能：拼接 url search 问号之后的部分, 不考虑 search 参数后存在 ？的情况, 如果存在请使用base64加密
   * 参数：
   *  1. url<string>: url 地址
   *  2. data<object>: 
   *  3. onlyUrl<boolean>: 是否仅返回新地址
   * 返回值：处理后的 url
   */
  serialize( url: any, data={}, onlyUrl=true ){

    if( !isString( url ) ) throw `Expected a string, but got a ${ getType( this ) }`

    if( url == '' || !isObject( data ) )  return url;

    const [ path, query ] = url.split( '?' );
    
    if( query ){
      data = { ...(this as any).split( query ), ...data };
    }

    const newQuery = (this as any).join( data );
    const newUrl = [ path, newQuery ].join( '?' ).replace( /\?$/, '' );

    if( onlyUrl ){
      return newUrl
    }

    return {
      params: data,
      newUrl
    }
  },
  
  /**
   * 功能：serialize 同名
   */
  parse( url: string, data: any, onlyUrl=true ){
    return (this as any).serialize( url, data, onlyUrl );
  }
})

