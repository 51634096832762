import { ENUM } from 'conf';
import { file2, func } from 'tools';
const { ATTACHMENT_TYPE, FOLLOW_TYPE } = ENUM;
/**
 * 功能：处理服务端发通话时间格式问题
 */
const handleCalltime = (recordContentArr: any[]) => {
  const data = recordContentArr.find( item => item.key == '通话时长' );
  if( data ){
    data.value = func.formatTime( 
      func.formatTime( data.value, 'toSec' ) 
    );
  }
}

/**
 * 功能：处理跟进记录
 */
const handleAttachment = (data: any) => {

  // 处理图片逻辑
  let fileList = ( data.fileList || [] ).map( 
    (im: any) => ({ 
      ...im, 
      name: im.url.split( '/' ).pop(),
      typeStr: ATTACHMENT_TYPE[im.type], 
      extName: file2.getType({ name: im.url }), 
      isImage: file2.isImage({ name: im.url })
    })
  ); 

  fileList = fileList.length == 0 ? null : fileList;

  const recordContentArr = [ ...(data.recordContentArr || []) ]

  handleCalltime( recordContentArr );
  
  const index = recordContentArr.findIndex( (item: any) => item.key == '跟进记录' );

  if( index < 0 ){
    recordContentArr.push({ key: '上传附件', value: fileList });
  }else{
    recordContentArr.splice( index, 0, { key: '上传附件', value: fileList });
  }
  return recordContentArr;
}

/**
 * 功能：处理跟进记录数据
 */
export const handleFollowRecord = (list: any[]) => {

  const ret: any[] = ( list || [] ).map( (item: any) => ({
    ...item,
    followTypeStr: FOLLOW_TYPE[ item.followType ],
    timeStr: item.createTime.split( ' ' )[1],
  })).reduce( (prevTotal: any[], item: any ) => {

    item.recordContentArr = handleAttachment( item );

    const { dateStr, recordTitle } = item;
    const idx = prevTotal.findIndex( (item: any) => dateStr == item.key );

    if( [ '新线索', '已转商机', '已上门', '已签约', '已批复', '已放款', '已成单' ].includes( recordTitle ) ){
      item.className = 'green';
    }else if( /服务终止$/.test( recordTitle ) ){
      item.className = 'red';
    }else{
      item.className = 'yellow';
    }

    if( idx < 0 ){ // 不存在
      return  [
        ...prevTotal,
        { key: dateStr, list: [ item ] }
      ];
    }else {
      const curItem = prevTotal[ idx ];
      curItem.list.push( item );
      return prevTotal
    }
  }, []);

  return Object.values( ret );
}







