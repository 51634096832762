/**
 * @author liantian
 * 功能：延迟函数
 */
export const delay = ( ms: number=1000 ): Promise<void> => {
  return new Promise( (resolve, reject) => {
    setTimeout( () => {
      resolve();
    }, ms );
  })
}