/**
 * @author lantian
 * @desc 线索操作类型
 */
export enum OPERATE_TYPE {
  FOLLOW = '只跟进',
  FORWARD = '推进',
  RESUME = '恢复',
  FALLBACK = '回退',
  TERMINATION = '流程终止',
  CHANGECONTRACT = '变更合同信息',
  CHANGEAPPROVAL = '变更批复信息',
  CHANGELOAN = '变更放款信息',
  CHANGESETTLEMENT = '变更结算信息',

  只跟进 = 'FOLLOW',
  推进 = 'FORWARD',
  恢复 = 'RESUME',
  回退 = 'FALLBACK',
  流程终止 = 'TERMINATION',
  变更合同信息 = 'CHANGECONTRACT',
  变更批复信息 = 'CHANGEAPPROVAL',
  变更放款信息 = 'CHANGELOAN',
  变更结算信息 = 'CHANGESETTLEMENT',
}