/**
 * 功能：将数组分割成二维数组
 * 参数：data<DataParams>, count<number>=3
 */

interface DataParams{
  key: string;
  value: string
}

export const groupByCount = (data: DataParams[], count: number=3 ): any[] => {
  const ret: any[] = [];
  let tempArr: any[] = [];

  data.forEach( ( item, index, arr ) => {
    tempArr.push(  item ) 
    if( ( index + 1 ) % count === 0 || arr.length === index + 1){
			ret.push( tempArr )
			tempArr = []
		}
  })

  return ret;
}