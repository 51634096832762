
import { Dict } from './../index.d';

/**
 * 功能: 排序函数
 * 参数:
 */
const sort = (list: Dict.Item[]) => list.sort( (a: Dict.Item, b: Dict.Item ) => a.orderIndex - b.orderIndex );

/**
 * 功能：格式化字典数据
 */
export const dictDataFormat = (list: Dict.Item[]): Dict.DataFormatReturns => {
  
  const len = list.length;
  
  let prevType = '';
  
  const ret = list.reduce( ( prevTotal: Dict.DataFormatReturns, item: Dict.Item, index: number ) => {

    item._label = item.keyDesc;
    item._value = item.keyValue;

    if( prevTotal[item.type] ){ 
      prevTotal[item.type].push( item );
    }else {

      // 排序处理
      if( prevTotal[prevType] ){
        prevTotal[prevType] = sort( prevTotal[prevType] );
      }
      
      prevTotal[item.type] = [ item ];
      prevType = item.type;
    }

    if( len - 1 == index ){
      prevTotal[item.type] = sort( prevTotal[item.type] );
    }

    return prevTotal;

  }, {} );

  return ret;

}