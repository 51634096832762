/**
 * 功能：将数组分组， 按指定 个数 分组
 * 参数：num，指定每组个数
 * 返回值：Array<Array>
 */
import { isArray, isNumber, getType } from 'class2type';

export default function( num ){

  if( !isArray( this ) ){
    throw `Expected an array, but got a ${ getType( this ) }`
  }

  if( !isNumber( num ) ){
    throw `Expected an number, but got a ${ getType( this ) }`;
  }

  const ret = [];

  if( this.length <= num ) return [ this ];

  this.forEach( ( item, index ) => {
    if( index % num === 0 ){
      ret.push( this.slice( index, index + num ) );
    }
  });

  return ret;

}