<template>
  <div class="wraper">
    <div class="wraper_top" :style="topStyle" >
      <slot name="top"></slot>
    </div>
    <div class="wraper_main" :style="mainStyle" >
      <slot name="main"></slot>
    </div>
    <div class="wraper_footer" :style="mainStyle" >
      <slot name="footer"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { onMounted } from 'vue';
export default {
  props: {
    topStyle: {
      default: {},
    },
    mainStyle: {
      default: {},
    },
  },
  setup(props, { emit }) {
    onMounted(() => {
      emit('wraperMounted')
    })
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
.wraper {
  .wraper_top {
    overflow: hidden;
    background: #ffffff;
    padding: 20px;
    margin-bottom: 20px;
  }
  .wraper_main {
    background: #ffffff;
    overflow: hidden;
    border-radius: 2px;
    padding: 20px;
  }
  .wraper_footer{
    background: #ffffff;
    overflow: hidden;
    border-radius: 2px;
    padding: 20px;
    margin-top: 20px;
  }
}
</style>
