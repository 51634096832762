/**
 * @author lantian
 * @desc 数据字典映射关系
 * /api/clue/dict
 */
export enum CLUE_DICT {
  COMMON_CONFIG="全部",
  REPAYMENT_TYPE =	"还款方式",
  CONTRACT_FEE_TYPE =	"合同收费模式",	
  CONTRACT_INPUT_TYPE =	"合同进件类型",
  CUSTOMER_INTENTION =	"客户意向",
  CUSTOMER_TYPE =	"客户类型",	
  FOLLOW_TYPE =	"跟进类型",	
  SERVICE_PHASE =	"服务阶段",
  SERVICE_STATUS =	"服务状态",
  TERMINATION_REASON =	"终止原因",
  ATTACHMENT_TYPE="上传附件类型",

  全部="COMMON_CONFIG",
  还款方式 = "REPAYMENT_TYPE",
  合同收费模式 = "CONTRACT_FEE_TYPE",	
  合同进件类型 = "CONTRACT_INPUT_TYPE",
  客户意向 = "CUSTOMER_INTENTION",
  客户类型 = "CUSTOMER_TYPE",	
  跟进类型 = "FOLLOW_TYPE",	
  服务阶段 = "SERVICE_PHASE",
  服务状态 = "SERVICE_STATUS",
  终止原因 = "TERMINATION_REASON",
  上传附件类型="ATTACHMENT_TYPE",
}




