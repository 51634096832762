/**
 * 功能：数组去重， 并处理数据重复的位置，只针对数组元素格式基本一致的数据格式
 * 参数： key, 针对数组元素为对象的去重， key 为指定字段, dir 方向， 保留数组后面或前面， 默认保留前面数据
 * 返回值：Array
 */
import { isObject, isArray, getType } from 'class2type';

export default function( key, dir=false ){

  if( !isArray( this ) ){
    throw `Expected an array, but got a ${ getType( this ) }`
  }

  const el = this[0]; // 检测是否为对象
  const keys = []; // 存储 key
  const ret = []; // 保存结果数据
  
  if( isObject( el ) ){

    const that = !dir ? this : this.reverse();

    that.forEach( item => {

      const curKey = item[ key ];

      if( !keys.includes( curKey ) ){
        keys.push( curKey );
        ret.push( item );
      }

    });

    return !dir ? ret: ret.reverse();

  }

  return [ ...new Set( this ) ];

}