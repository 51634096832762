export default {
  tabTitle: '',
  token: '',
  userInfo: {},
  permissions: [],
  navList: [],
  orderSettlement: {},
  nav3: [],
  messageNum:{ // 看板的所有消息总和 以及 类型的未读消息
    count:0,
  },
  tags: [],
  channel: [],
  routeList:[],
  isFormTiled:'',
  isCityTiled:'',
  cityList:[],
  chooseFillColor:'EEF4FF',
  backgroundColor:'',
  captcha_font_color:'',
  submit_fill_color:'',
  pageCont:"1", //第几页
  isBoundPhoneNumber: false
};
