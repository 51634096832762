<template>
  <el-dialog :model-value="isBoundPhoneNumber" @close="handleClose" title="提示" width="420px">
    <el-form ref="formRef" :model="form">
      <el-form-item>
        <div style="font-size: 12px;">您的账号尚未绑定手机号码，绑定后支持使用手机短信验证码直接登录</div>
      </el-form-item>
      <el-form-item label="" prop="mobile" :rules="[{ validator: checkMobileNumber, required: true, trigger: 'change' }]">
        <el-input v-model="form.mobile" placeholder="请输入您的手机号码" clearable />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleResetForm">暂不绑定</el-button>
        <el-button type="primary" @click="handleSubmitForm">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'NotBoundPhone',
  data() {
    return {
      // dialogFormVisible: false,
      form: {
        mobile: ''
      }
    }
  },
  computed: {
    isBoundPhoneNumber() {
      return this.$store.state.isBoundPhoneNumber
    }
  },
  methods: {
    checkMobileNumber(rule, value, callback) {
      const reg = /^1[3-9]\d{9}$/
      if (value === '') {
        callback(new Error('请输入您的手机号码'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    },
    handleSubmitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const url = `/sys/user/mobileBind?mobile=${this.form.mobile}`
          this.$postData(url).then(res => {
            const data = res.data
            if (data.code === 200) {
              this.$message.success(data.message)
            }
            this.$store.commit('UPDATE_DATA', { key: 'isBoundPhoneNumber', value: false})
          })
        }
      })
    },
    handleResetForm() {
      this.$refs.formRef.resetFields()
      this.$store.commit('UPDATE_DATA', { key: 'isBoundPhoneNumber', value: false})
    },
    handleClose() {
      if (this.isBoundPhoneNumber) {
        this.$store.commit('UPDATE_DATA', { key: 'isBoundPhoneNumber', value: false})
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>