/**
 * @author lantian
 * func： 过滤 json 数据
 * params：data，json 数据，keys，保留或删除的的键值，remain 默认保留
 *  1. data<object>: json 数据
 *  2. keys<array|string>: 键
 *  3. remaineboolean>: 默认为 true，
 * return：object
 */
import { isObject, isString } from 'class2type';
import { loop } from './../loop';
export const pick = ( data: any, keys: string | string[], remain=true ) => {
  const rest = {};
  const d = { ...data };

  if( !isObject( data ) ) return data;

  if( isString( keys ) ) {
    keys = ( <String>keys ).trim().split( /\s*,\s*/ )
  }

  loop( data, ( key: string, value: any) => {
    const hasKey = keys.includes( key );
    if( hasKey ){
      (<any>rest)[ key ] = value;
      delete d[ key ];
    }
  });

  return remain ? rest : d
}

