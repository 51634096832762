/**
 * @author lantian
 * @desc 跟进类型
 */
export enum FOLLOW_TYPE {
  TELEPHONE = '电话',
  WECHAT = '微信',
  MESSAGE = '短信',
  INTERVIEW = '面谈',
  OTHER = '其他',
  FALLBACK = '回退阶段',

  电话 = 'TELEPHONE',
  微信 = 'WECHAT',
  短信 = 'MESSAGE',
  面谈 = 'INTERVIEW',
  其他 = 'OTHER',
  回退阶段 = 'FALLBACK',
}