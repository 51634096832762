/**
 * 功能：获取数组最后一个元素
 */
import { isArray } from 'class2type';

export default function(){

  if( !isArray( this ) ){
    throw `Expected an array, but got a ${ getType( this ) }` 
  }

  return this.slice( -1 );
  
}