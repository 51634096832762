/**
 * @author lantian
 * @desc 入池方式，线索来源
 */
export enum PUT_POOL_TYPE {
  PT_LAUNCH = "左心房投放",
  PT_NATIVE = "友商推送",
  HAND_CREATE = "手动创建",
  JD_LAUNCH = "金蝶投放",
  JR_LAUNCH = "捷融投放",
}